/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
const CFG = process.env

export const NODE_URL = CFG.REACT_APP_NODE_URL
export const HASURA_URL = CFG.REACT_APP_HASURA_URL
export const HASURA_WSS = CFG.REACT_APP_HASURA_WSS
export const HASURA_KEY = CFG.REACT_APP_HASURA_KEY
export const TELEGRAM_TOKEN = CFG.REACT_APP_TELEGRAM_TOKEN
export const ENCRYPTED_SECRET_KEY = CFG.REACT_APP_ENCRYPTED_SECRET_KEY

export const formatEnums = (enums) =>
  enums.reduce((obj, { id, text, type, value, metadata, role }) => {
    obj[type]
      ? obj[type].push({ key: id, value, type, text, metadata, role })
      : (obj[type] = [{ key: id, value, type, text, metadata, role }])
    return obj
  }, {})
