import moment from 'moment'

import gq from '../queries'
import utils from '@utils'

import {
  qNotifications,
  insertNotification,
  deleteNotification,
  updateNotification,
  qTodayNotifications,
} from '../../queries/notifications'
import { NOTIFICATION_TYPES as PRODUCT_TYPES } from '../reducers/notifications'

export const fetchNotifications = () => async (dispatch) => {
  try {
    const { boorran_Notifications: notifications } = await gq.gqRequest(qNotifications)

    dispatch({
      type: PRODUCT_TYPES.FETCH_NOTIFICATIONS,
      payload: notifications,
    })
  } catch (error) {
    console.log(`Error fetch notifications: ${error.message}`)
  }
}

export const createNotification = (data) => async (dispatch) => {
  try {
    const start = moment(data.start).utcOffset(7).startOf('day').toISOString()
    const end = moment(data.end).utcOffset(7).endOf('day').toISOString()
    let image = data?.image || null

    if (image) {
      image = await utils.helpers.uploadToFirebase({
        file: data.image,
        path: `notification-${moment().format('x')}`,
      })
    }

    const variables = { request: { ...data, start, end, image } }
    const response = await gq.gqRequest(insertNotification, variables)

    const responseNotification = response?.insert_boorran_Notifications?.returning[0] || false

    if (!responseNotification) throw Error

    dispatch({
      type: PRODUCT_TYPES.CREATE_NOTIFICATION,
      payload: responseNotification,
    })
  } catch (err) {
    alert(`Fail to create notifications: ${err.message}`)
  }
}

export const removeNotification = (id) => async (dispatch, getState) => {
  try {
    const { data: notifications } = getState().notifications

    const variables = { id }
    await gq.gqRequest(deleteNotification, variables)

    const newNotifications = notifications.filter((data) => data.id !== id)

    dispatch({
      type: PRODUCT_TYPES.DELETE_NOTIFICATION,
      payload: newNotifications,
    })
  } catch (err) {
    alert(`Fail to remove notifications: ${err.message}`)
  }
}

export const editNotification = (data) => async (dispatch, getState) => {
  try {
    const { data: notifications } = getState().notifications
    const start = moment(data.start).utc().startOf('day').toISOString()
    const end = moment(data.end).utc().endOf('day').toISOString()

    let image = data?.image || null

    if (image) {
      image = await utils.helpers.uploadToFirebase({
        file: data.image,
        path: `notification-${moment().format('x')}`,
      })
    }

    const variables = { id: data.id, request: { ...data, start, end, image } }

    const {
      update_boorran_Notifications: { returning },
    } = await gq.gqRequest(updateNotification, variables)

    if (!returning[0]) throw Error

    const newNotificatons = notifications.map((notification) =>
      data.id === notification.id ? returning[0] : notification
    )

    dispatch({
      type: PRODUCT_TYPES.UPDATE_NOTIFICATION,
      payload: newNotificatons,
    })
  } catch (err) {
    alert(`Fail to update notifications: ${err.message}`)
  }
}

export const getTodayNotification = () => async (dispatch, getState) => {
  try {
    const variables = { today: moment().utc().startOf('day').toISOString() }
    const { boorran_Notifications: notifications } = await gq.gqRequest(
      qTodayNotifications,
      variables
    )
    dispatch({
      type: PRODUCT_TYPES.FETCH_TODAY_NOTIFICATION,
      payload: notifications,
    })
  } catch (error) {
    alert(`Fail to get today notifications: ${error.message}`)
  }
}
