const CACHE_NAME = 'image-cache-v1'
const IMAGE_CACHE = [
  // Add static image URLs here if needed
  '/fallback-image.jpg', // Fallback image for network errors
]

// Maximum number of images to cache
const MAX_CACHE_ITEMS = 50

// Install the service worker and cache static images
self.addEventListener('install', (event) => {
  console.log('[Service Worker] Install')
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      console.log('[Service Worker] Caching static images')
      return cache.addAll(IMAGE_CACHE)
    })
  )
})

// Intercept fetch requests
self.addEventListener('fetch', (event) => {
  if (event.request.destination === 'image') {
    event.respondWith(
      caches.match(event.request).then((cachedResponse) => {
        if (cachedResponse) {
          console.log(`[Service Worker] Serving cached image: ${event.request.url}`)
          return cachedResponse
        }

        console.log(`[Service Worker] Fetching image: ${event.request.url}`)
        return fetch(event.request)
          .then((fetchedResponse) => {
            if (
              !fetchedResponse ||
              fetchedResponse.status !== 200 ||
              fetchedResponse.type !== 'basic'
            ) {
              return fetchedResponse
            }

            return caches.open(CACHE_NAME).then((cache) => {
              // Cache the fetched response and limit the cache size
              cache.put(event.request, fetchedResponse.clone())
              limitCacheSize(CACHE_NAME, MAX_CACHE_ITEMS)
              return fetchedResponse
            })
          })
          .catch(() => {
            console.log('[Service Worker] Fetch failed; serving fallback image')
            return caches.match('/fallback-image.jpg')
          })
      })
    )
  }
})

// Clean up old caches during activation
self.addEventListener('activate', (event) => {
  console.log('[Service Worker] Activate')
  event.waitUntil(
    caches.keys().then((cacheNames) =>
      Promise.all(
        cacheNames.map((cacheName) => {
          if (cacheName !== CACHE_NAME) {
            console.log(`[Service Worker] Deleting old cache: ${cacheName}`)
            return caches.delete(cacheName)
          }
        })
      )
    )
  )
})

// Utility: Limit cache size by removing the oldest items
async function limitCacheSize(cacheName, maxItems) {
  const cache = await caches.open(cacheName)
  const keys = await cache.keys()
  if (keys.length > maxItems) {
    console.log('[Service Worker] Cache size exceeded; deleting oldest item')
    await cache.delete(keys[0])
    limitCacheSize(cacheName, maxItems) // Ensure the cache size is limited
  }
}
