import React, { Suspense, lazy } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import client from './config/apollo'
import useAuthState from './hook/useAuthState'
const Routes = lazy(() => import('./routes'))
const Loading = lazy(() => import('@components/Loading'))
const Error = lazy(() => import('@components'))

const selectAuth = createSelector(
  (state) => state.auth,
  (auth) => ({
    error: auth.error && Object.keys(auth.error).length ? auth.error : null,
    authenticated: Boolean(auth.data && Object.keys(auth.data).length),
    loading: auth.loading,
  })
)

const App = React.memo(() => {
  const dispatch = useDispatch()
  const { error, loading } = useAuthState(selectAuth, dispatch)

  if (loading) return <Loading text="Signing you in..." />
  if (error) return <Error message={error.message} />

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<Loading text="Loading..." />}>
        <Routes />
      </Suspense>
    </ApolloProvider>
  )
})

export default App
