/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'
import utils from '@utils'

export const PRODUCT_TYPES = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  PRODUCTS_SEARCH: 'PRODUCTS_SEARCH',
  PRODUCT_FETCHED: 'PRODUCT_FETCHED',
  PRODUCTS_FETCHED: 'PRODUCTS_FETCHED',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  FETCHED_CHECKOUT_PRODUCTS: 'FETCHED_CHECKOUT_PRODUCTS',
  FETCHED_CHECKOUT_PRODUCTS_VARIANTS: 'FETCHED_CHECKOUT_PRODUCTS_VARIANTS',
  SET_SHOPIFY_PRODUCTS: 'SET_SHOPIFY_PRODUCTS',
  SET_LOADING: 'SET_LOADING',
  SET_LOADING_VARIANTS: 'SET_LOADING_VARIANTS',
  SET_QUERY_COLLECTION: 'SET_QUERY_COLLECTION',
  SET_SHOW_MORE: 'SET_SHOW_MORE',
  SET_NEXT_PAGINATION: 'SET_NEXT_PAGINATION',
  SET_PAGINATE_LOADING: 'SET_PAGINATE_LOADING',
  FETCH_PRODUCTS_FRONT: 'FETCH_PRODUCTS_FRONT',
}

const initialState = Immutable({
  list: [],
  search: [],
  product: [],
  products: [],
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
  data: {},
  variants: {},
  productData: [],
  shopifyProducts: [],
  productsAggregate: 0,
  nextPagination: null,
  paginateLoading: false,
  activeCollection: '',
  query: utils.constants.queryCollection,
  showMore: '',
  loading: false,
  loadingVariants: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPES.PRODUCTS_FETCHED:
      const { limit, offset, data, pages, page } = action.payload

      return state.set('list', data.products).set('pagination', {
        limit,
        offset,
        pages,
        page,
        count: data.productCount.aggregate.count,
      })
    // .set('loading', false);

    case PRODUCT_TYPES.PRODUCTS_SEARCH:
      return state.set('search', action.payload)

    case PRODUCT_TYPES.PRODUCT_FETCHED:
      return state.set('product', action.payload)

    case PRODUCT_TYPES.SET_PRODUCTS:
      return state.set('products', action.payload)
    // .set('loading', false);
    case PRODUCT_TYPES.FETCH_PRODUCTS_FRONT:
      return state
        .set('productData', [...state.productData, ...action.payload.products])
        .set('nextPagination', action.payload.pagination)
        .set('productsAggregate', action.payload.productsAggregate)
        .set('paginateLoading', false)

    case PRODUCT_TYPES.FETCHED_CHECKOUT_PRODUCTS:
      const { products } = action.payload
      return state.set('data', products).set('loading', false)

    case PRODUCT_TYPES.SET_LOADING:
      return state.set('loading', action.loading)

    case PRODUCT_TYPES.SET_LOADING_VARIANTS:
      return state.set('loadingVariants', action.loading)

    case PRODUCT_TYPES.SET_SHOPIFY_PRODUCTS:
      return state.set('shopifyProducts', action.payload)

    case PRODUCT_TYPES.SET_PAGINATE_LOADING:
      return state.set('paginateLoading', action.loading)

    case PRODUCT_TYPES.SET_NEXT_PAGINATION:
      return state.set('nextPagination', action.payload)

    case PRODUCT_TYPES.SET_SHOW_MORE:
      return state.set('showMore', action.productName)

    case PRODUCT_TYPES.SET_QUERY_COLLECTION:
      return state
        .set('query', action.payload.query)
        .set('activeCollection', action.payload.collection)

    case PRODUCT_TYPES.FETCHED_CHECKOUT_PRODUCTS_VARIANTS:
      const { productId, productVariants, singleVariant, productType } = action.payload

      return state
        .set('variants', {
          ...state.variants,
          [productId]: {
            productType,
            productVariants,
            isSingleVariant: singleVariant,
          },
        })
        .set('loadingVariants', false)

    default:
      return state
  }
}
