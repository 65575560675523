/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/no-cycle */
import Geocode from 'react-geocode'
import find from 'lodash/find'
import utils from '@utils'

Geocode.setApiKey('AIzaSyBwPfQ9QX2b3JLQoomU7TtBy-kUDXi6cTw')

const getGeocodeFromLatLng = async ({ lat, lng }) => {
  const response = await Geocode.fromLatLng(lat, lng)

  const LOCATION_TYPES = ['APPROXIMATE', 'GEOMETRIC_CENTER']
  const nearestAddress = find(
    response.results,
    (result) =>
      LOCATION_TYPES.includes(result.geometry.location_type) &&
      !result.formatted_address.includes('+') &&
      !result.formatted_address.includes('Unnamed Road')
  )

  const addressComponents = nearestAddress.address_components
  const state = addressComponents.find(
    (component) => component.types[0] === 'administrative_area_level_1'
  )
  const address = utils.format.locationName(addressComponents, state)

  return { address, city: state.long_name, position: { lat, lng } }
}

const serializeAutocomplete = (place, placeName) => {
  // const address = place.formatted_address
  const address = utils.format.sliceLocationName(placeName)
  const addressComponents = place.address_components

  const state = addressComponents.find(
    (component) => component.types[0] === 'administrative_area_level_1'
  )

  const lat = place.geometry.location.lat()
  const lng = place.geometry.location.lng()

  return { address, city: state.long_name, position: { lat, lng } }
}

const getCurrentPosition = () =>
  new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition((position) => {
      resolve({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  })

export default {
  serializeAutocomplete,
  getGeocodeFromLatLng,
  getCurrentPosition,
}
