/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { POST, wsGQL, wsGQLclose } from 'fetchier'
import Cookie from 'js-cookie'
import { AUTH_TYPES } from '../types'
import { formatEnums, HASURA_KEY, HASURA_WSS, NODE_URL } from '../config'

import { ORDER_TYPES } from '../reducers/orders'
import { SETTINGS_TYPES } from '../reducers/settings'

import { fetchUsers, setEnums } from './orders'
import { getTodayNotification } from './notifications'
// import { getDeviceIPAddress, getTodayAttendance } from './attendances'
// import { getDefaultLocation, getAttedanceConfig } from './settings'

const setData = (data) => ({
  type: AUTH_TYPES.SET_DATA,
  data,
})

const setLoading = (loading) => ({
  type: AUTH_TYPES.SET_LOADING,
  loading,
})

const error = (err) => (dispatch) => {
  dispatch(setLoading(false))
  dispatch({ type: AUTH_TYPES.SET_ERROR, payload: err })
  console.error(err)
}

// const requestIpAddress = () => async (dispatch) => {
//   try {
//     const ipAddress = await getDeviceIPAddress()

//     dispatch({
//       type: SETTINGS_TYPES.SETTING_IPADDRESS,
//       payload: ipAddress,
//     })
//   } catch (err) {
//     console.log(err)
//   }
// }

const login = (body, callback) => (dispatch) => {
  dispatch(setLoading(true))

  const req = { url: `${NODE_URL}/auth` }
  const isUUID = typeof body === 'string' && body.split('-').length === 5

  if (!isUUID && (!body.email || !body.pin)) return dispatch(error('Not enough credential'))

  isUUID ? (req.headers = { Authorization: body }) : (req.body = body)

  return POST(req)
    .then(
      async ({
        data: {
          session: { id, user },
        },
      }) => {
        Cookie.set('sessionToken', id, { expires: 365 })

        Promise.all([dispatch(fetchUsers()), dispatch(setEnums()), dispatch(initWs())])
          .then(([{ Users }]) => {
            dispatch(setData(user))
            dispatch(getTodayNotification())

            Users = Users.filter((user) => !!user.activationStatus).map(
              ({ id, name, role, metadata }) => ({
                id,
                type: 'users',
                text: name,
                value: id,
                role,
                metadata,
              })
            )

            dispatch({
              type: ORDER_TYPES.ENUMS_FETCHED,
              payload: formatEnums([...Users]),
            })

            callback && callback()
          })
          .catch((err) => dispatch(error(err)))
      }
    )
    .catch((err) => {
      dispatch(error(err))
      dispatch(setData({}))
    })
}

const authenticate = () => (dispatch) => {
  const sessionToken = Cookie.get('sessionToken')

  if (sessionToken) {
    return dispatch(login(sessionToken))
  }

  return dispatch(setData({}))
}

const initWs = () => async (dispatch) =>
  await wsGQL({
    url: HASURA_WSS,
    headers: { 'x-hasura-admin-secret': HASURA_KEY },
  })

const logout = () => (dispatch) => {
  dispatch(setLoading(true))

  setTimeout(() => {
    dispatch(setData({}))
    Cookie.remove('sessionToken')
    wsGQLclose()
  }, 50)
}

const resetPin = (data) => {
  const sessionToken = Cookie.get('sessionToken')
  const req = {
    url: `${NODE_URL}/auth/reset-pin`,
    body: data,
    headers: { Authorization: sessionToken },
  }
  return POST(req)
    .then((res) => console.log('success', res))
    .catch((err) => console.log(err))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  error,
  logout,
  setData,
  resetPin,
  setLoading,
  authenticate,
}
